import { defineStore } from 'pinia'
import type { User, Token, Credentials, ConsumerDataPackageResponse } from '~/types/authConsumer'

export const useConsumerAuthStore = defineStore('consumerAuth', () => {
    const user = ref<User | null>(null)
    const token = ref<Token | null>(null)
    const dataPackage = ref<ConsumerDataPackageResponse | null>(null)
    const isLoggedIn = computed(() => !!user.value)

    // Track the current time of the player
    const playerCurrentTime = ref(0)

    // Track the user's experience path order
    const experiencePathOrder = ref(0)

    // Track the time lest in the current experience path
    const experienceTimeLeft = ref(0)

    // Track the end time of the current experience path
    const experienceEndTime = ref(0)

    // Track the current URL of the HLS stream
    const currentUrlHls = ref('')

    // Track when to retrieve the scorecard
    const getScorecard = ref(false)

    // Track if the experience has ended
    const isEnded = ref(false)

    // Track when to leave the meeting
    const leaveMeeting = ref(false)

    // Track when to join the breakout room
    const joinBreakoutRoom = ref(false)

    // Add new state for video muting
    const shouldMuteVideo = ref(false)

    // Fetch the user data
    async function fetchUser(): Promise<void> {
        try {
            const token = localStorage.getItem('token')
            if (!token) {
                throw new Error('No token found')
            }

            const data = await useApiFetch<User>('/api/user', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })

            if (!data) {
                throw new Error('Failed to fetch user data')
            }

            user.value = data
        } catch (error) {
            console.error('Failed to fetch user:', error)
            clear()
            throw error
        }
    }

    // Log the user in
    async function login(credentials: Credentials): Promise<Token> {
        try {
            await useApiFetch('/sanctum/csrf-cookie')

            const login = await useApiFetch<Token>('/api/exp/auth', {
                params: {
                    email: credentials.email,
                    sessionId: credentials.sessionId,
                }
            })

            if (!login?.token) {
                throw new Error('Invalid login response')
            }

            localStorage.setItem('token', login.token)
            await fetchUser()

            return login
        } catch (error) {
            console.error('Login failed:', error)
            throw error
        }
    }

    // Get the data package
    async function fetchDataPackage(sessionId: number) {
        try {
            const data = await useFetchConsumerDataPackage(sessionId)

            if (!data) {
                throw new Error('Failed to fetch data package')
            }

            dataPackage.value = data
        } catch (error) {
            console.error('Failed to get data:', error)
            throw error // Re-throwing error for better error handling upstream
        }
    }

    // Clear the user data
    function clear() {
        user.value = null
        token.value = null
        dataPackage.value = null
        localStorage.removeItem('token')
        resetExperienceState()
    }

    // Add after other functions
    function resetExperienceState() {
        playerCurrentTime.value = 0
        experiencePathOrder.value = 0
        experienceTimeLeft.value = 0
        experienceEndTime.value = 0
        currentUrlHls.value = ''
        getScorecard.value = false
        isEnded.value = false
        leaveMeeting.value = false
        joinBreakoutRoom.value = false
        shouldMuteVideo.value = false
    }

    // Add after other functions
    function getStoredToken(): string | null {
        return localStorage.getItem('token')
    }

    function setToken(newToken: string): void {
        token.value = { token: newToken }
        localStorage.setItem('token', newToken)
    }

    function hasValidToken(): boolean {
        return !!getStoredToken()
    }

    return {
        user,
        token,
        dataPackage,
        isLoggedIn,
        playerCurrentTime,
        experiencePathOrder,
        experienceTimeLeft,
        experienceEndTime,
        currentUrlHls,
        getScorecard,
        isEnded,
        leaveMeeting,
        joinBreakoutRoom,
        shouldMuteVideo,
        login,
        fetchUser,
        fetchDataPackage,
        clear,
        resetExperienceState,
        getStoredToken,
        setToken,
        hasValidToken
    }
})
