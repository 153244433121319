// plugins/sentry.client.ts
import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import type { User, Breadcrumb, BreadcrumbHint } from '@sentry/vue'
import type { Primitive } from '@sentry/types'

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp
  const config = useRuntimeConfig()
  const isDev = import.meta.dev

  // Early return if in development mode
  if (isDev) {
    // Provide mock Sentry functions for development with proper types
    return {
      provide: {
        sentrySetContext: (name: string, context: { [key: string]: any } | null): void => {},
        sentrySetUser: (user: User | null): void => {},
        sentrySetTag: (key: string, value: Primitive): void => {},
        sentryAddBreadcrumb: (breadcrumb: Breadcrumb, hint?: BreadcrumbHint): void => {},
        sentryCaptureException: (error: any): string => {
          console.error('[Dev Mode - Sentry Disabled]:', error)
          return 'mock-error-id'
        }
      }
    }
  }

  Sentry.init({
    app: [vueApp],
    dsn: config.public.SENTRY_DSN,
    environment: 'production',
    integrations: [
      Sentry.browserTracingIntegration({
        router: nuxtApp.$router as Router
      }),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      if (event.exception) {
        console.error(`[Exception handled by Sentry]: (${hint.originalException})`, { event, hint })
      }
      return event
    }
  })

  vueApp.mixin(Sentry.createTracingMixins({
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  }))

  Sentry.attachErrorHandler(vueApp, {
    attachErrorHandler: true,
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException
    }
  }
})