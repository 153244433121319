import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import type { User, Token, Credentials, LoginResponse, LoginResult } from '../types/auth'

// Constants should be uppercase and potentially moved to a constants file
const TOKEN_EXPIRATION_DELAY: number = 3 * 60 * 60 * 1000 // 3 hours

export const useAuthStore = defineStore('auth', () => {
    const user = ref<User | null>(null)
    const token = ref<Token | null>(null)
    const isLoggedIn = computed(() => !!user.value && user.value.user_type_id === 2)
    
    // Track the user's credits
    const credits = ref(0)

    // Track if the user's credits should be fetched
    const fetchCredits = ref(false)

    const error = ref<string | null>(null)

    /**
     * Sets the authentication token with expiration
     */
    function setAuthToken(token: string): void {
        try {
            const expirationTime = Date.now() + TOKEN_EXPIRATION_DELAY
            localStorage.setItem('token', token)
            localStorage.setItem('token_expiration', expirationTime.toString())
        } catch (error) {
            console.error('Failed to set auth token:', error)
            // Could emit an event or handle error differently
        }
    }

    /**
     * Fetches the user data from the server.
     * @returns Promise<void>
     * @throws Error if fetch fails
     */
    async function fetchUser(): Promise<void> {
        try {
            if (isTokenExpired()) {
                logout()
                return
            }

            const storedToken = localStorage.getItem('token')
            if (!storedToken) return

            const data = await useApiFetch<User>('/api/user', {
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                }
            })
            
            user.value = data
        } catch (error) {
            console.error('Failed to fetch user:', error)
            throw error
        }
    }

    /**
     * Logs in the user and stores the token in the local storage.
     * @throws Error if login fails
     */
    async function login(credentials: Credentials): Promise<LoginResult> {
        try {
            // Get the CSRF token and update set-cookie
            await useApiFetch('/sanctum/csrf-cookie')

            const response = await useApiFetch<LoginResponse>('/api/login', {
                method: 'POST',
                body: JSON.stringify(credentials),
            })

            if (!response?.token) {
                throw new Error('Invalid login response')
            }

            // Store the token and set expiration
            setAuthToken(response.token)
            
            // Fetch the user data
            await fetchUser()

            return {
                token: response.token,
                success: true
            }

        } catch (error) {
            console.error('Login failed:', error)
            return {
                token: '',
                success: false,
                message: error instanceof Error ? error.message : 'Login failed'
            }
        }
    }

    /**
     * Logs in the user using a social provider and stores the token in the local storage.
     */
    async function loginSocial(provider: string, token: string): Promise<LoginResult> {
        try {
            // Get the CSRF token and update set-cookie
            await useApiFetch('/sanctum/csrf-cookie')

            const response = await useApiFetch<LoginResponse>(`/api/auth/${provider}/verify`, {
                method: 'POST',
                body: JSON.stringify({
                    provider: provider,
                    token: token
                }),
            })

            if (!response?.token) {
                throw new Error('Invalid social login response')
            }

            // Store the token and set expiration
            setAuthToken(response.token)
            
            // Fetch the user data
            await fetchUser()

            return {
                token: response.token,
                success: true
            }

        } catch (error) {
            console.error('Social login failed:', error)
            return {
                token: '',
                success: false,
                message: error instanceof Error ? error.message : 'Social login failed'
            }
        }
    }

    /**
     * Basic logout that just resets the store state
     * Main logout logic moved to useLogout composable
     */
    function logout() {
        user.value = null
        token.value = null
        credits.value = 0
        fetchCredits.value = false
        localStorage.removeItem('token')
        localStorage.removeItem('token_expiration')
    }

    /**
     * Checks if the token is expired.
     */
    function isTokenExpired(): boolean {
        const expirationTime = localStorage.getItem('token_expiration')
        return !expirationTime || Date.now() > parseInt(expirationTime)
    }

    // Add getters for derived state
    const fullName = computed(() => {
        if (!user.value) return ''
        return `${user.value.first_name} ${user.value.last_name}`.trim()
    })

    const userProfile = computed(() => user.value?.profile ?? null)

    /**
     * Refreshes the token by calling the refresh-token endpoint.
     */
    async function refreshToken(): Promise<void> {
        try {
            const response = await useApiFetch<LoginResponse>('/api/refresh-token', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            })
            if (response?.token) {
                setAuthToken(response.token)
            }
        } catch (error) {
            console.error('Failed to refresh token:', error)
            logout()
        }
    }

    /**
     * Initializes the auth store by fetching the user data if the token is valid.
     */
    async function init() {
        const storedToken = localStorage.getItem('token')
        if (storedToken && !isTokenExpired()) {
            await fetchUser()
        } else {
            logout()
        }
    }

    /**
     * Sets the error message.
     */
    function setError(message: string | null) {
        error.value = message
    }

    return {
        user,
        login,
        loginSocial,
        isLoggedIn,
        fetchUser,
        token,
        logout,
        credits,
        fetchCredits,
        // Export new computed properties
        fullName,
        userProfile,
        refreshToken,
        init,
        error,
        setError
    }
})
