import Ably from 'ably'

export default defineNuxtPlugin(nuxtApp => {
  // Only initialize Ably for experience-related routes
  const route = useRoute()
  const isExperienceRoute = route.path.startsWith('/experience')

  if (!isExperienceRoute) {
    return
  }

  const config = useRuntimeConfig()
  const ably = new Ably.Realtime({
    key: config.public.ABLY_API_KEY,
    clientId: 'nuxt-app', //optional
  })
  nuxtApp.provide('ably', ably)
})