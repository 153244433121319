import { defineNuxtPlugin, watch } from '#imports'
import { useRuntimeConfig } from 'nuxt/app'
import Intercom from '@intercom/messenger-js-sdk'
import { useAuthStore } from '~/stores/useAuthStore'
import { useConsumerAuthStore } from '~/stores/useConsumerAuthStore'

export default defineNuxtPlugin<{ intercom: typeof Intercom }>((nuxtApp) => {
  // Skip Intercom initialization in test environment
  if (process.env.NODE_ENV === 'test') {
    return {
      provide: {
        intercom: () => {} // Provide a no-op function for tests
      }
    }
  }

  const config = useRuntimeConfig()
  const adminStore = useAuthStore()
  const consumerStore = useConsumerAuthStore()

  const initIntercom = () => {
    const baseConfig = {
      app_id: config.public.intercomAppId as string,
    }

    // Check for admin user first
    if (adminStore.isLoggedIn && adminStore.user) {
      const user = adminStore.user
      Intercom({
        ...baseConfig,
        user_id: String(user.id),
        name: `${user.first_name} ${user.last_name}`.trim(),
        email: user.email,
        created_at: Math.floor(new Date(user.email_verified_at || Date.now()).getTime() / 1000),
        user_type: 'admin'
      })
    }
    // Then check for consumer user
    else if (consumerStore.isLoggedIn && consumerStore.dataPackage?.user) {
      const { user } = consumerStore.dataPackage
      Intercom({
        ...baseConfig,
        user_id: String(user.id),
        name: `${user.first_name} ${user.last_name}`.trim(),
        email: user.email,
        created_at: Math.floor(new Date(user.created_at || Date.now()).getTime() / 1000),
        user_type: 'consumer'
      })
    }
    // Anonymous user
    else {
      Intercom(baseConfig)
    }
  }

  // Initialize Intercom when the app is mounted
  nuxtApp.vueApp.mixin({
    mounted() {
      if (this.$parent === null) {
        initIntercom()
      }
    }
  })

  // Watch for admin auth state changes
  watch(
    () => adminStore.isLoggedIn,
    () => initIntercom()
  )

  // Watch for consumer auth state changes and data package
  watch(
    [() => consumerStore.isLoggedIn, () => consumerStore.dataPackage],
    () => initIntercom()
  )

  // Provide a composable to access Intercom methods
  return {
    provide: {
      intercom: Intercom
    }
  }
}) 