<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: {
    type: Object as PropType<NuxtError>,
    required: true
  }
})

// Fun messages based on error type
const errorMessages = {
  404: {
    title: "Oops! Page Not Found",
    message: "Looks like this page took a coffee break ☕"
  },
  500: {
    title: "Server is Having a Bad Day",
    message: "Our server needs a hug... and maybe a restart 🤗"
  },
  default: {
    title: "Something Went Wrong",
    message: "Even the best of us trip sometimes 🤷‍♂️"
  }
}

const errorContent = computed(() => {
  const statusCode = props.error?.statusCode as keyof typeof errorMessages
  return errorMessages[statusCode] || errorMessages.default
})

// Set SEO meta for error page
useSeoMeta({
  title: computed(() => errorContent.value.title),
  description: computed(() => errorContent.value.message)
})

// Handle error clearing
const handleError = () => {
  clearError({ redirect: '/' })
}
</script>

<template>
  <div class="min-h-screen bg-gradient-to-b from-fuchsia-50 via-purple-50 to-pink-50">
    <Header />

    <UMain>
      <UContainer>
        <div class="py-16 text-center">
          <h1
            class="text-4xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent mb-4">
            {{ errorContent.title }}
          </h1>

          <p class="text-xl text-gray-600 mb-8">
            {{ errorContent.message }}
            <span class="text-sm block mt-2 text-gray-500">
              {{ props.error?.statusMessage || '' }}
            </span>
          </p>

          <div class="flex justify-center gap-4">
            <UButton color="purple" @click="handleError" class="hover:scale-105 transition-transform">
              Take Me Home
            </UButton>
          </div>
        </div>
      </UContainer>
    </UMain>

    <Footer />
  </div>
</template>

<style scoped>
.animate-bounce-slow {
  animation: bounce 3s infinite;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-25px);
  }
}
</style>
