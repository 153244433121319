import type { UseFetchOptions } from '#app'
import type { NitroFetchOptions } from 'nitropack'

interface ApiError {
  response: {
    _data: {
      error: string;
      message?: string;
    };
    status: number;
  };
}

export async function useApiFetch<T>(
  path: string | (() => string),
  options: Partial<UseFetchOptions<T>> = {}
) {
  const headers: HeadersInit = {}
  const config = useRuntimeConfig()

  const xsrfToken = useCookie('XSRF-TOKEN')
  const sessionCookie = useCookie('laravel_session')

  if (xsrfToken.value) {
    headers['X-XSRF-TOKEN'] = decodeURIComponent(xsrfToken.value)
  }

  headers['Accept'] = 'application/json'
  headers['Content-Type'] = 'application/json'

  if (import.meta.server) {
    const requestHeaders = useRequestHeaders(['cookie', 'referer', 'host'])
    
    if (requestHeaders.cookie) {
      headers['cookie'] = requestHeaders.cookie
    }
    if (requestHeaders.referer) {
      headers['referer'] = requestHeaders.referer
    }
    if (requestHeaders.host) {
      headers['host'] = requestHeaders.host
    }
  }

  try {
    const response = await $fetch<T>(config.public.apiBase + path, {
      credentials: 'include',
      watch: false,
      ...options,
      headers: {
        ...headers,
        ...(options.headers || {})
      },
      withCredentials: true
    } as NitroFetchOptions<any>)

    return response
  } catch (error) {
    const err = error as ApiError
    
    if (err.response?._data) {
      const errorMessage = err.response._data.error || err.response._data.message
      console.error(`API Error (${err.response.status}):`, errorMessage)
      throw new Error(errorMessage ?? 'An unknown error occurred')
    }
    
    console.error('Network Error:', error)
    throw new Error('Unable to connect to the server.')
  }
}