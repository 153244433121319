import { default as _91slug_93uh1lhTT9DcMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/blog/[slug].vue?macro=true";
import { default as indexnBgk0nFQgbMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/blog/index.vue?macro=true";
import { default as blogDe2m0Qk7chMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/blog.vue?macro=true";
import { default as checkoutSuccessVK4C1ePO0bMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/checkoutSuccess.vue?macro=true";
import { default as dashboardcnEVvknpX8Meta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/dashboard.vue?macro=true";
import { default as _91sessionId_93D6DY6uytymMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/experience/[sessionId].vue?macro=true";
import { default as endQ0Pk51r7uRMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/experience/end.vue?macro=true";
import { default as _91_46_46_46slug_93zUFi210f9yMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/experience/live/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93nKW0CE7SnhMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/experience/wait/[...slug].vue?macro=true";
import { default as indexNdN5eNlQ9SMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/index.vue?macro=true";
import { default as loginWeVHcBhJw0Meta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/login.vue?macro=true";
import { default as pricingXpskFmhVNVMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/pricing.vue?macro=true";
import { default as signup3cBUbOtMWOMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/signup.vue?macro=true";
import { default as systemCheckJewY7JLMLvMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/systemCheck.vue?macro=true";
import { default as terms5DfwHXJIPTMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/terms.vue?macro=true";
import { default as _91_46_46_46slug_93J2S0ZHy0ybMeta } from "/codebuild/output/src753972716/src/synergos-frontend/pages/verify/[...slug].vue?macro=true";
import { default as component_45stubWfbE08vkU2Meta } from "/codebuild/output/src753972716/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubWfbE08vkU2 } from "/codebuild/output/src753972716/src/synergos-frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: blogDe2m0Qk7chMeta?.name,
    path: "/blog",
    meta: blogDe2m0Qk7chMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/blog.vue"),
    children: [
  {
    name: "blog-slug",
    path: ":slug()",
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "",
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/blog/index.vue")
  }
]
  },
  {
    name: "checkoutSuccess",
    path: "/checkoutSuccess",
    meta: checkoutSuccessVK4C1ePO0bMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/checkoutSuccess.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardcnEVvknpX8Meta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/dashboard.vue")
  },
  {
    name: "experience-sessionId",
    path: "/experience/:sessionId()",
    meta: _91sessionId_93D6DY6uytymMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/experience/[sessionId].vue")
  },
  {
    name: "experience-end",
    path: "/experience/end",
    meta: endQ0Pk51r7uRMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/experience/end.vue")
  },
  {
    name: "experience-live-slug",
    path: "/experience/live/:slug(.*)*",
    meta: _91_46_46_46slug_93zUFi210f9yMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/experience/live/[...slug].vue")
  },
  {
    name: "experience-wait-slug",
    path: "/experience/wait/:slug(.*)*",
    meta: _91_46_46_46slug_93nKW0CE7SnhMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/experience/wait/[...slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexNdN5eNlQ9SMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginWeVHcBhJw0Meta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/login.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingXpskFmhVNVMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/pricing.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup3cBUbOtMWOMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/signup.vue")
  },
  {
    name: "systemCheck",
    path: "/systemCheck",
    meta: systemCheckJewY7JLMLvMeta || {},
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/systemCheck.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/terms.vue")
  },
  {
    name: "verify-slug",
    path: "/verify/:slug(.*)*",
    component: () => import("/codebuild/output/src753972716/src/synergos-frontend/pages/verify/[...slug].vue")
  },
  {
    name: component_45stubWfbE08vkU2Meta?.name,
    path: "/docs",
    component: component_45stubWfbE08vkU2
  }
]