import { ApiErrorCode, getErrorMessage } from '~/types/apiErrors'
import type { ConsumerDataPackageResponse } from '~/types/authConsumer'

export const useFetchConsumerDataPackage = async (sessionId: number) => {
  const token = localStorage.getItem('token')
  
  try {
    const response = await useApiFetch<ConsumerDataPackageResponse>('/api/consumer/getdatapackage', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        sessionId,
      },
    })
    
    if (!response) {
      throw new Error('No data received from API')
    }
    
    return response
  } catch (error) {
    const message = getErrorMessage((error as Error).message as ApiErrorCode)
    throw new Error(message)
  }
}
