/**
 * Enum representing all possible API error codes
 */
export enum ApiErrorCode {
  // Consumer related errors
  CONSUMER_001 = 'CONSUMER_001', // Consumer not found
  CONSUMER_002 = 'CONSUMER_002', // Consumer not assigned to a session
  CONSUMER_003 = 'CONSUMER_003', // Consumer already assigned to a session
  
  // Session related errors
  SESSION_001 = 'SESSION_001',   // Session not found
  SESSION_002 = 'SESSION_002',   // Insufficient credits
  SESSION_003 = 'SESSION_003',   // Session create failed
  SESSION_004 = 'SESSION_004',   // Session update failed
  SESSION_005 = 'SESSION_005',   // Session delete failed
  SESSION_006 = 'SESSION_006',   // Session already completed
  SESSION_007 = 'SESSION_007',   // Session consumers not found
  SESSION_008 = 'SESSION_008',   // Session notification failed
  SESSION_009 = 'SESSION_009',   // Session consumer not found
  SESSION_010 = 'SESSION_010',   // Session cannot be cancelled
  SESSION_011 = 'SESSION_011',   // Session cancel failed
  
  // User related errors
  USER_001 = 'USER_001',         // User not found
  USER_002 = 'USER_002',         // User create failed
  USER_003 = 'USER_003',         // User update failed
  USER_004 = 'USER_004',         // User delete failed
  USER_005 = 'USER_005',         // User already exists
  USER_006 = 'USER_006',         // User invalid type
  USER_007 = 'USER_007',         // User email exists
  USER_008 = 'USER_008',         // User notification not found
  USER_009 = 'USER_009',         // User unauthorized
  USER_010 = 'USER_010',         // User profile not found
  
  // Company related errors
  COMPANY_001 = 'COMPANY_001',   // Company mismatch
  
  // Breakout room related errors
  BREAKOUT_001 = 'BREAKOUT_001', // Breakout room not found
  BREAKOUT_002 = 'BREAKOUT_002', // Consumer already in breakout
  
  // Experience related errors
  EXPERIENCE_001 = 'EXPERIENCE_001', // Experience not assigned
  EXPERIENCE_002 = 'EXPERIENCE_002', // Experience not found
  
  // Request related errors
  REQUEST_001 = 'REQUEST_001',    // Invalid request

  // Checkout related errors
  CHECKOUT_001 = 'CHECKOUT_001',  // Checkout failed
  CHECKOUT_002 = 'CHECKOUT_002',  // Order not found

  // Setting related errors
  SETTING_001 = 'SETTING_001',    // Setting delete failed
  SETTING_002 = 'SETTING_002',    // Setting not found
}

/**
 * Interface for API Error response
 */
export interface ApiErrorResponse {
  code: ApiErrorCode;
  message: string;
}

/**
 * Map of error codes to their corresponding messages
 */
const ERROR_MESSAGES: Record<ApiErrorCode, string> = {
  [ApiErrorCode.CONSUMER_001]: 'Consumer not found',
  [ApiErrorCode.CONSUMER_002]: 'Consumer not assigned to a session',
  [ApiErrorCode.CONSUMER_003]: 'Consumer already assigned to a session',
  
  [ApiErrorCode.SESSION_001]: 'Session not found',
  [ApiErrorCode.SESSION_002]: 'Insufficient credits',
  [ApiErrorCode.SESSION_003]: 'Failed to create session',
  [ApiErrorCode.SESSION_004]: 'Failed to update session',
  [ApiErrorCode.SESSION_005]: 'Failed to delete session',
  [ApiErrorCode.SESSION_006]: 'Session is already completed',
  [ApiErrorCode.SESSION_007]: 'Session consumers not found',
  [ApiErrorCode.SESSION_008]: 'Failed to send session notification',
  [ApiErrorCode.SESSION_009]: 'Session consumer not found',
  [ApiErrorCode.SESSION_010]: 'Session cannot be cancelled',
  [ApiErrorCode.SESSION_011]: 'Failed to cancel session',
  
  [ApiErrorCode.USER_001]: 'User not found',
  [ApiErrorCode.USER_002]: 'Failed to create user',
  [ApiErrorCode.USER_003]: 'Failed to update user',
  [ApiErrorCode.USER_004]: 'Failed to delete user',
  [ApiErrorCode.USER_005]: 'User already exists',
  [ApiErrorCode.USER_006]: 'Invalid user type',
  [ApiErrorCode.USER_007]: 'Email already exists',
  [ApiErrorCode.USER_008]: 'User notification not found',
  [ApiErrorCode.USER_009]: 'User unauthorized',
  [ApiErrorCode.USER_010]: 'User profile not found',
  
  [ApiErrorCode.COMPANY_001]: 'Consumer and session are not from the same company',
  
  [ApiErrorCode.BREAKOUT_001]: 'Breakout room not found',
  [ApiErrorCode.BREAKOUT_002]: 'Consumer already assigned to a breakout room',
  
  [ApiErrorCode.EXPERIENCE_001]: 'Experience not assigned to session',
  [ApiErrorCode.EXPERIENCE_002]: 'Experience not found',
  [ApiErrorCode.REQUEST_001]: 'Invalid request',
  
  [ApiErrorCode.CHECKOUT_001]: 'Checkout process failed',
  [ApiErrorCode.CHECKOUT_002]: 'Order not found',
  
  [ApiErrorCode.SETTING_001]: 'Failed to delete setting',
  [ApiErrorCode.SETTING_002]: 'Setting not found'
};

/**
 * Gets the corresponding error message for a given error code
 * @param code - The API error code
 * @returns The corresponding error message or a default message if code is not found
 */
export const getErrorMessage = (code: ApiErrorCode): string => {
  return ERROR_MESSAGES[code] || 'An unknown error occurred';
};

/**
 * Checks if a given string is a valid API error code
 * @param code - The code to check
 * @returns True if the code is a valid ApiErrorCode
 */
export const isApiErrorCode = (code: string): code is ApiErrorCode => {
  return Object.values(ApiErrorCode).includes(code as ApiErrorCode);
};

/**
 * Creates an API error response object
 * @param code - The API error code
 * @returns An ApiErrorResponse object with code and message
 */
export const createApiError = (code: ApiErrorCode): ApiErrorResponse => {
  return {
    code,
    message: getErrorMessage(code)
  };
}; 